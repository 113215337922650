@import url('https://fonts.googleapis.com/css2?family=Antonio&family=Montserrat:wght@700;800&display=swap');

* {
    font-family: Montserrat;
    margin: 0px;
    padding: 0px;
    user-select: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Background Styling */
.dashboard-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to right, #141e30, #243b55);
    color: white;
}

/* Card Design */
.dashboard-card {
    padding: 30px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
    text-align: center;
    width: 400px;
}

/* Text Styling */
.loading-text {
    font-size: 26px;
    font-weight: bold;
    color: #4facfe;
    animation: pulse 1.5s infinite;
}

.redirect-text {
    font-size: 24px;
    font-weight: bold;
    background: -webkit-linear-gradient(#ff9a9e, #fad0c4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.error-text {
    font-size: 20px;
    color: #ff4d4d;
    font-weight: bold;
}

/* Button Styling */
.redirect-button {
    margin-top: 20px;
    padding: 12px 20px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    background: linear-gradient(to right, #00b09b, #96c93d);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.redirect-button:hover {
    transform: scale(1.1);
    background: linear-gradient(to right, #0072ff, #00c6ff);
}

/* Pulse Animation */
@keyframes pulse {
    0% {
        opacity: 0.6;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.6;
    }
}