@import url('https://fonts.googleapis.com/css2?family=Antonio&family=Montserrat:wght@700;800&display=swap');

* {
  font-family: Montserrat;
  margin: 0px;
  padding: 0px;
  user-select: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

.StartUpPage {
  background-color: #212121;
  max-width: 110%;
  height: 100%;
  overflow: hidden;
  background-image: url("./img/background.svg");
  background-repeat: no-repeat;
  background-size: 90%;
}

.StartUpStart {
  margin: 7vw 7vw;
  width: auto;
  color: #EFEFEF;
  z-index: 1;
}

.StartUpTitle {
  color: #EFEFEF;
  font-size: 100px;
  text-align: left;
  width: fit-content;
  z-index: 1;
}

.StartUpDescription {
  color: #EFEFEF;
  font-size: 15px;
  min-width: 200px;
  width: 40vw;
  justify-self: left;
  text-align: left;
  z-index: 1;
}

.GetStarted {
  justify-self: left;
  z-index: 1;
}

.GetStarted button {
  color: #EFEFEF;
  background-color: transparent;
  border: 0px;
  font-size: 50px;
  z-index: 1;
}

.kupan-container {
  position: relative;
  margin-top: 25px;
  overflow: auto;
}

.svg-image {
  position: relative;
  top: 0;
  left: 0;
  width: 600px;
  height: auto;
}

.qr-code {
  position: absolute;
  top: -5px;
  left: 30px;
  width: 215px;
}

.text-overlay {
  font-size: 14px;
  position: absolute;
  top: 150px;
  left: 360px;
  width: 200px;
  text-wrap: nowrap;
}

@media screen {
  
}

.loginPage {
  background-color: #212121;
  background-image: url("./img/login.png");
  background-repeat: no-repeat;
  background-size: cover;
}