.accept-coupons-container {
    padding: 30px;
}

h2 {
    font-weight: bold;
    color: #333;
}

.card {
    border-radius: 12px;
    background: #f9f9f9;
    border: none;
}

p {
    font-size: 16px;
    color: #555;
}

.button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
}

button {
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 8px;
    font-weight: bold;
}

button.btn-success {
    background: #28a745;
    border: none;
}

button.btn-success:hover {
    background: #218838;
}

button.btn-danger {
    background: #dc3545;
    border: none;
}

button.btn-danger:hover {
    background: #c82333;
}

.alert {
    font-weight: bold;
}
